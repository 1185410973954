import React from 'react';

export function Loadable<TProps extends {}>(options: {
  loader: () => Promise<{ default: React.ComponentType<TProps> }>;
  loading: () => React.ReactElement;
}) {
  const Component = React.lazy(options.loader);
  return function LoadableComponent(props: TProps) {
    return (
      <React.Suspense fallback={options.loading()}>
        <Component {...props} />
      </React.Suspense>
    );
  };
}
