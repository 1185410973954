import { MuiThemeProvider } from '@material-ui/core';
import { SessionLockProvider } from 'Components/App/sessionLock';
import { ShellValuesProvider } from 'Components/App/withShellControls';
import PrivateRoute from 'Components/shared/PrivateRoute';
import ScreenLoader from 'Components/shared/ScreenLoader';
import { SnackbarProvider } from 'Components/shared/snackbarUtils';
import { useUser } from 'Components/withUser';
import createTheme, { SectionThemeConsumer, SectionThemeProvider } from 'Root/theme';
import { Loadable } from 'Services/Loadable';
import { tracker } from 'Services/tracker.js';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import get from 'lodash/get';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

const LandingPage = Loadable({
  loader: () => import('Components/Authentication/LandingPage'),
  loading: ScreenLoader,
});

const Shell = Loadable({
  loader: () => import('./Shell'),
  loading: ScreenLoader,
});

export default function App() {
  const user = useUser();

  useEffect(() => {
    if (window.location.hostname === 'localhost') return;

    tracker?.start().then(() => {
      console.log('OpenReplay started');
    });
  }, []);

  const ldClient = useLDClient();

  useEffect(() => {
    if (!ldClient || !user.email) return;

    ldClient.identify({
      kind: 'user',
      key: user.email,
      email: user.email,
      displayName: user.displayName,
      id: user.id,
    });
  }, [ldClient, user.email]);

  return (
    <SectionThemeProvider>
      <SectionThemeConsumer>
        {({ value: sectionTheme }) => (
          <MuiThemeProvider theme={createTheme(get(user, 'settings.theme'), sectionTheme)}>
            <SnackbarProvider>
              <SessionLockProvider>
                <ShellValuesProvider>
                  <Switch>
                    <Route
                      path={['/login', '/forgot-password', '/register']}
                      component={LandingPage}
                    />
                    <PrivateRoute path="/" component={Shell} />
                  </Switch>
                </ShellValuesProvider>
              </SessionLockProvider>
            </SnackbarProvider>
          </MuiThemeProvider>
        )}
      </SectionThemeConsumer>
    </SectionThemeProvider>
  );
}
