import { useUser } from 'Components/withUser';
import { type ReactNode, createContext, useContext } from 'react';

export const mediaQueries = {
  mobile: '(max-width: 960px)',
  tablet: '(max-width: 1280px)',
};

/**
 * A color palette that can be used to theme the application. The colors are organized by hue and lightness.
 * Each hue has a range of lightness from 50 to 950, with 50 being the lightest and 950 being the darkest.
 *
 * Two exceptions to this are 'white' and 'black', which have a range of opacities rather than lightness.
 *
 * The palette is also organized into two themes: 'light' and 'dark'. The dark palette provides the same colors, but with
 * the lightness values reversed, so 50 is the darkest and 950 is the lightest. Each palette also has a 'reverse' property
 * that provides the opposite theme's colors.
 *
 * Again, the exception to this is 'white' and 'black', which are referred to as the 'contrast' color in the theme palette.
 * In the light palette, the contrast color is black, and in the dark palette, the contrast color is white. The opacity
 * is not reversed for these colors.
 *
 * These are meant to provide easy ways to reference colors that work in both palettes. Don't overthink it. If the
 * theme colors don't work, just specify what you want with a conditional.
 */
export type Palette = PaletteMainColors & {
  /**
   * The lightest color in the palette. Not actually white, but a very light version of slate.
   * The numeric keys are the opacity values.
   */
  white: OpacityPalette;

  /**
   * The darkest color in the palette. Not actually black, but a very dark version of slate.
   * The numeric keys are the opacity values.
   */
  black: OpacityPalette;

  /**
   * The light theme palette.
   */
  light: ThemePalette;

  /**
   * The dark theme palette.
   */
  dark: ThemePalette;
};

interface PaletteMainColors {
  gray: typeof gray;
  slate: typeof slate;
  cobalt: typeof cobalt;
  sky: typeof sky;
  red: typeof red;
  orange: typeof orange;
  green: typeof green;
  purple: typeof purple;
}

export type PaletteColor = keyof PaletteMainColors;

export type ThemePalette = PaletteMainColors & {
  neutral: OpacityPalette;
  contrast: OpacityPalette;
  reverse: PaletteMainColors;
};

type OpacityPalette = typeof white;

const white = {
  100: 'hsla(215, 30%, 99%, 1)',
  95: 'hsla(215, 30%, 99%, 0.95)',
  90: 'hsla(215, 30%, 99%, 0.9)',
  85: 'hsla(215, 30%, 99%, 0.85)',
  80: 'hsla(215, 30%, 99%, 0.8)',
  70: 'hsla(215, 30%, 99%, 0.7)',
  60: 'hsla(215, 30%, 99%, 0.6)',
  50: 'hsla(215, 30%, 99%, 0.5)',
  40: 'hsla(215, 30%, 99%, 0.4)',
  30: 'hsla(215, 30%, 99%, 0.35)',
  25: 'hsla(215, 30%, 99%, 0.20)',
  20: 'hsla(215, 30%, 99%, 0.25)',
  15: 'hsla(215, 30%, 99%, 0.20)',
  10: 'hsla(215, 30%, 99%, 0.15)',
  5: 'hsla(215, 30%, 99%, 0.10)',
};

/**
 * The darkest color in the palette. Not actually black, but a very dark version of slate.
 * The numeric keys are the opacity values.
 */
const black = {
  100: 'hsla(215, 30%, 6%, 1)',
  95: 'hsla(215, 40%, 3%, 0.95)',
  90: 'hsla(215, 40%, 3%, 0.9)',
  85: 'hsla(215, 40%, 3%, 0.85)',
  80: 'hsla(215, 40%, 3%, 0.8)',
  70: 'hsla(215, 40%, 3%, 0.7)',
  60: 'hsla(215, 40%, 3%, 0.6)',
  50: 'hsla(215, 40%, 3%, 0.5)',
  40: 'hsla(215, 40%, 3%, 0.4)',
  30: 'hsla(215, 40%, 3%, 0.3)',
  25: 'hsla(215, 40%, 3%, 0.25)',
  20: 'hsla(215, 40%, 3%, 0.2)',
  15: 'hsla(215, 40%, 3%, 0.15)',
  10: 'hsla(215, 40%, 3%, 0.1)',
  5: 'hsla(215, 40%, 3%, 0.05)',
};

const gray = {
  50: white[100], // 99%
  // 5
  100: 'hsl(215, 10%, 94%)', // main page bg
  // 6
  150: 'hsl(215, 10%, 88%)',
  // 10
  200: 'hsl(215, 5%, 80%)',
  // // 10
  300: 'hsl(215, 5%, 72%)',
  // // 12
  400: 'hsl(215, 5%, 62%)',
  // // 12
  500: 'hsl(215, 5%, 54%)',
  // // 12
  600: 'hsl(215, 5%, 45%)',
  // // 12
  700: 'hsl(215, 7%, 38%)',
  // // 10
  800: 'hsl(215, 7%, 27%)',
  // 10
  850: 'hsl(215, 10%, 19%)',
  // 8
  900: 'hsl(215, 13%, 12%)', // main page bg
  // 7
  950: black[100], // 3%
};

/**
 * The neutral color. Used for backgrounds, borders, plain text, disabled elements, and other elements that are not meant to draw attention.
 */
const slate = {
  50: 'hsl(215, 35%, 94%)',
  100: 'hsl(215, 35%, 88%)',
  150: 'hsl(215, 35%, 84%)',
  200: 'hsl(215, 35%, 80%)',
  300: 'hsl(215, 35%, 72%)',
  400: 'hsl(215, 35%, 64%)',
  500: 'hsl(215, 35%, 54%)',
  600: 'hsl(215, 35%, 44%)',
  700: 'hsl(215, 35%, 34%)',
  800: 'hsl(215, 35%, 26%)',
  850: 'hsl(215, 35%, 22%)',
  900: 'hsl(215, 35%, 18%)',
  950: 'hsl(215, 35%, 12%)',
};

/**
 * A color used to draw attention to an element, without indicating that it is clickable or interactive.
 * It should be used for things like titles, headers, and other important text or elements.
 */
const cobalt = {
  50: 'hsl(215, 100%, 95%)',
  100: 'hsl(215, 100%, 92%)',
  150: 'hsl(215, 100%, 90%)',
  200: 'hsl(215, 100%, 88%)',
  250: 'hsl(215, 100%, 82%)',
  300: 'hsl(215, 90%, 75%)',
  350: 'hsl(215, 90%, 70%)',
  400: 'hsl(215, 90%, 60%)',
  500: 'hsl(215, 80%, 50%)',
  600: 'hsl(215, 80%, 40%)',
  700: 'hsl(215, 80%, 30%)',
  800: 'hsl(215, 75%, 20%)',
  900: 'hsl(215, 75%, 16%)',
  950: 'hsl(215, 75%, 12%)',
};

/**
 * The "active" color. Used to indicate that an element is clickable or interactive in some way (e.g. buttons, links, etc)
 */
const sky = {
  50: 'hsl(200, 100%, 98%)',
  100: 'hsl(200, 100%, 93%)',
  200: 'hsl(200, 100%, 85%)',
  300: 'hsl(200, 100%, 75%)',
  400: 'hsl(200, 100%, 65%)',
  500: 'hsl(200, 100%, 50%)',
  600: 'hsl(200, 100%, 45%)',
  700: 'hsl(200, 100%, 35%)',
  800: 'hsl(200, 100%, 25%)',
  900: 'hsl(200, 100%, 15%)',
  950: 'hsl(200, 100%, 10%)',
};

// The following colors are used for specific purposes and should not be used for general theming.

const red = {
  50: 'hsl(5, 80%, 98%)',
  100: 'hsl(5, 80%, 92%)',
  200: 'hsl(5, 80%, 83%)',
  300: 'hsl(5, 80%, 72%)',
  400: 'hsl(5, 80%, 60%)',
  500: 'hsl(5, 80%, 50%)',
  600: 'hsl(5, 80%, 45%)',
  700: 'hsl(5, 80%, 35%)',
  800: 'hsl(5, 80%, 25%)',
  900: 'hsl(5, 80%, 15%)',
  950: 'hsl(5, 80%, 10%)',
};

const orange = {
  50: 'hsl(30, 100%, 98%)',
  100: 'hsl(30, 100%, 93%)',
  200: 'hsl(30, 100%, 85%)',
  300: 'hsl(30, 100%, 75%)',
  400: 'hsl(30, 100%, 60%)',
  500: 'hsl(30, 100%, 50%)',
  600: 'hsl(30, 100%, 44%)',
  700: 'hsl(30, 100%, 35%)',
  800: 'hsl(30, 100%, 25%)',
  900: 'hsl(30, 100%, 15%)',
  950: 'hsl(30, 100%, 10%)',
};

const green = {
  // hue 120, saturation 80%
  50: 'hsl(150, 60%, 98%)',
  100: 'hsl(150, 60%, 93%)',
  200: 'hsl(150, 60%, 85%)',
  300: 'hsl(150, 60%, 75%)',
  400: 'hsl(150, 60%, 65%)',
  500: 'hsl(150, 60%, 55%)',
  600: 'hsl(150, 60%, 45%)',
  700: 'hsl(150, 60%, 35%)',
  800: 'hsl(150, 60%, 25%)',
  900: 'hsl(150, 60%, 15%)',
  950: 'hsl(150, 60%, 10%)',
};

const purple = {
  50: 'hsl(290, 80%, 98%)',
  100: 'hsl(290, 80%, 90%)',
  200: 'hsl(290, 80%, 80%)',
  300: 'hsl(290, 80%, 65%)',
  400: 'hsl(290, 80%, 45%)',
  500: 'hsl(290, 80%, 40%)',
  600: 'hsl(290, 80%, 35%)',
  700: 'hsl(290, 80%, 30%)',
  800: 'hsl(290, 80%, 25%)',
  900: 'hsl(290, 80%, 15%)',
  950: 'hsl(290, 80%, 10%)',
};

const mainColors: PaletteMainColors = {
  gray,
  slate,
  cobalt,
  sky,
  red,
  orange,
  green,
  purple,
};

/**
 * A palette of colors for the dark theme. The colors are generated from the light palette by reversing the order
 * of the colors of each hue.
 */
const dark = Object.entries(mainColors).reduce((acc, [hue, values]) => {
  const keys = Object.keys(values).map(Number);
  acc[hue] = Object.fromEntries(
    Object.values(values)
      .reverse()
      .map((value, i) => [keys[i], value]),
  );
  return acc;
}, {} as PaletteMainColors);

export const palette: Palette = {
  ...mainColors,

  white,
  black,

  light: {
    ...mainColors,
    neutral: white,
    contrast: black,
    reverse: dark,
  },
  dark: {
    ...dark,
    neutral: black,
    contrast: white,
    reverse: mainColors,
  },
};

export type ThemeMode = 'light' | 'dark';
export type ForcedTheme = ThemeMode | 'user';

const forcedThemeContext = createContext<ForcedTheme>('user');

/**
 * Returns the mode name (light/dark) and palette for the current theme. This returns either the theme set by the
 * user (in ThemeContext), forced by the UI (with a ForceTheme context) if applicable. If `forceTheme` is provided, it
 * will override any of those theme values.
 */
export function useTheme(forceTheme?: ForcedTheme): {
  theme: ThemeMode;
  themeColors: ThemePalette;
} {
  const user = useUser();
  const contextForcedTheme = useContext(forcedThemeContext);
  const forcedTheme = forceTheme ?? contextForcedTheme;
  const theme = (forcedTheme === 'user' && (user.settings.theme || 'light')) || forcedTheme;

  return {
    theme,
    themeColors: palette[theme],
  };
}

/**
 * Forces a theme mode (light/dark) in child components. This is useful for testing or for components that need to
 * render in a specific theme mode regardless of the user's settings. When this is use, calling `useTheme` in child
 * components will return the forced theme mode, or the user's theme mode if no theme is forced.
 *
 * @param theme The theme mode to force. This can be one of:
 *   - `"light"` or `"dark"`
 *   - `"user"` to force the theme back to the user's setting
 *   - `undefined` to prevent forcing a theme and just use the current theme
 *     (this is useful when you need to wrap in `<ForceTheme>` but only want to conditionally force the theme)
 * @param children
 */
export function ForceTheme({
  theme,
  children,
}: { theme: ForcedTheme | undefined; children: ReactNode }) {
  const { theme: currentTheme } = useTheme();
  if (theme === undefined) theme = currentTheme;
  return <forcedThemeContext.Provider value={theme}>{children}</forcedThemeContext.Provider>;
}
