import { IconButton } from '@material-ui/core';
import orange from '@material-ui/core/colors/orange';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import { SnackbarProvider as SnackbarProviderNotiStack } from 'notistack';
import SnackbarContext from 'notistack/build/SnackbarContext';
import React from 'react';
import { compose, hoistStatics, wrapDisplayName } from 'recompose';

export const withSnackbar = hoistStatics((Component) => {
  const WithSnackbar = (props) => (
    <SnackbarContext.Consumer>
      {(context) => (
        <Component
          {...props}
          enqueueSnackbar={context.handleEnqueueSnackbar}
          closeSnackbar={context.handleCloseSnackbar}
        />
      )}
    </SnackbarContext.Consumer>
  );

  if (process.env.NODE_ENV !== 'production') {
    WithSnackbar.displayName = wrapDisplayName(Component, 'withSnackbar');
  }

  return WithSnackbar;
});

export function useSnackbar() {
  const s = React.useContext(SnackbarContext);
  return {
    enqueueSnackbar: s.handleEnqueueSnackbar,
    closeSnackbar: s.handleCloseSnackbar,
  };
}

export const CloseSnackbarButton = withSnackbar(({ snackbarKey, closeSnackbar }) => (
  <IconButton
    key="close"
    aria-label="Close"
    color="inherit"
    onClick={() => closeSnackbar(snackbarKey)}
  >
    <CloseIcon />
  </IconButton>
));

const styles = (theme) => ({
  snackbar: {
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(120px)',
    },
    [theme.breakpoints.down('sm')]: {
      transform: 'translateY(-20px)',
    },
  },
  snackbarWarning: {
    backgroundColor: orange[800],
  },
});

export const SnackbarProvider = compose(
  withWidth(),
  withStyles(styles),
)((props) => (
  <SnackbarProviderNotiStack
    {...props}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    classes={{
      root: props.classes.snackbar,
      variantWarning: props.classes.snackbarWarning,
    }}
    action={(snackbarKey) => <CloseSnackbarButton snackbarKey={snackbarKey} />}
    TransitionProps={{ direction: isWidthUp('md', props.width) ? 'left' : 'down' }}
  />
));
