import { tryJsonParse } from 'Services/util/tryJsonParse';
import type { DeepPartial } from 'Services/util/types';
import merge from 'lodash/merge';
import { useCallback, useState } from 'react';

export type DebugValues = null | DeepPartial<{
  session: {
    expiration: number;
    idleLock: number;
    logoutTimer: number;
  };
}>;

let debugValues = tryJsonParse<DebugValues>(localStorage.getItem('debug')) || null;

/**
 * Returns the debug values.
 * Use this only when you can't use a React hook.
 */
export function getDebugValues(): DebugValues {
  return debugValues || null;
}

/**
 * Sets the debug values.
 * Use this only when you can't use a React hook.
 */
export function setDebugValues(values: DebugValues) {
  if (values) {
    if (!debugValues) debugValues = {};
    merge(debugValues, values);
  } else {
    debugValues = null;
  }
  localStorage.setItem('debug', JSON.stringify(debugValues));
}

export function useDebugValues() {
  const [, rerender] = useState({});

  const setValues = useCallback((values: DebugValues) => {
    setDebugValues(values);
    rerender({});
  }, []);

  return [debugValues, setValues] as const;
}

declare global {
  interface Window {
    enableDebug: (values: DebugValues) => void;
    disableDebug: () => void;
  }
}

window.enableDebug = (values: DebugValues) => {
  setDebugValues(values || {});
};

window.disableDebug = () => {
  setDebugValues(null);
};
