import moment from 'moment';
import { GetEnvironment } from './dtos';
import client from './services/client';

/**
 * This handler ensures that the website does a full reload when
 * the javascript files get updated on the server, but the client
 * does not have all of the scripts downloaded already.
 * Because we lazy load some modules, the script bundle behind that module
 * may be gone between the time that the user initially loads the index page
 * and the time that they finally navigate to a part of the app that requires
 * the lazy loaded module. To see this in action take the following steps:
 * 1. Build the client for production.
 *    Your scripts should have hashes in their names under wwwroot/dist/.
 * 2. Start the Web API project in production mode that way it doesn't
 *    serve assets from the webpack middleware.
 * 3. Load the index page in your browser.
 * 4. Without closing your browser tab, stop the app,
 *    build the assets again to produce a new hash and start the app back up.
 * 5. In your browser tab that you've used before,
 *    navigate to a different module. This could be the agency admin section.
 */
const MIN_TIME_BETWEEN_RELOADS = 1;

async function reloadIfNeeded() {
  try {
    if (!window.VITE_BUILD_VERSION) {
      console.log('No build version found (window.VITE_BUILD_VERSION), skipping reload check');
      return;
    }

    console.log(`Checking server version against client version (${window.VITE_BUILD_VERSION})`);
    client.headers.set('X-Bypass-Refresh', 'true');
    const { version } = await client.get(new GetEnvironment());
    client.headers.delete('X-Bypass-Refresh');
    if (version === window.VITE_BUILD_VERSION) {
      console.log('Version is okay, no need to reload');
      return;
    }

    console.warn(`Server version (${version}) is different from client`);

    const lastReloadTime = Number.parseInt(localStorage.getItem('lastReloadTime'), 10);
    const lastReloadDate = lastReloadTime ? moment(lastReloadTime) : null;

    // We can force reload the user if it's been
    // more than a minute since the last time we reloaded them.
    // This is to prevent reload cycles which can be catastrophic
    const canSafelyReload =
      !lastReloadDate || moment().isAfter(lastReloadDate.add(MIN_TIME_BETWEEN_RELOADS, 'minute'));

    if (!canSafelyReload) {
      console.warn(
        `Code version is outdated, but not forcing reload since it has been less than ${MIN_TIME_BETWEEN_RELOADS} minutes since the last reload.`,
      );
      return;
    }

    console.warn('Forcing reload since app update was detected.');

    localStorage.setItem('lastReloadTime', Date.now().toString());

    window.location = `/?_cacheBust=${Date.now()}`;
  } catch (e) {
    console.error('Failed to reload outdated javascript', e);
  }
}

export function ensureFailedChunksForcesAReload() {
  window.addEventListener('error', (e) => {
    if (e.message.includes('error loading dynamically imported module')) {
      reloadIfNeeded();
    }
  });
  reloadIfNeeded();

  // Check for new version every hour
  setInterval(reloadIfNeeded, 60 * 60 * 1000);
}
