import { UserContext } from 'Components/UserProvider';
import { useContext } from 'react';
import { hoistStatics, wrapDisplayName } from 'recompose';

export default (propName = 'user') =>
  hoistStatics((Component) => {
    const WithUser = (props) => (
      <UserContext.Consumer>
        {(user) => <Component {...props} {...{ [propName]: user }} />}
      </UserContext.Consumer>
    );
    WithUser.displayName = wrapDisplayName(Component, 'withUser');
    return WithUser;
  });

export function useUser() {
  return useContext(UserContext);
}
