/**
 * Tries to parse a JSON string and returns the parsed object or a default value if the parsing fails.
 * Note that `null` is a valid JSON value (not a failure), so this function will return `null`.
 * Use something like `tryJsonParse(json) ?? {}` if you want to default to an empty object.
 *
 * @param json The JSON string to try parsing.
 * @param defaultValue The default value to return if parsing fails.
 */
export function tryJsonParse<T>(json: string | null, defaultValue: T): T;
export function tryJsonParse<T>(json: string | null): T | undefined;
export function tryJsonParse<T>(json: string | null, defaultValue?: T): T | undefined {
  try {
    return json ? JSON.parse(json) : defaultValue;
  } catch (e) {
    return defaultValue;
  }
}
