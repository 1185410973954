import MomentUtils from '@date-io/moment';
import { Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import UserProvider from 'Components/UserProvider';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import svg4everybody from 'svg4everybody';
import { ensureFailedChunksForcesAReload } from './chunkLoadErrorHandler';
import App from './components/App';
import { loadEnvironment } from './services/environment';
import { persistor, store } from './store/configureStore';

import './app.scss';
import 'Services/testChatService';
import { NewInvestigationProvider } from 'Components/App/NewInvestigationsContext';
import type { EnvironmentResponse } from 'Root/dtos';
import { useEffect, useState } from 'react';

svg4everybody();
ensureFailedChunksForcesAReload();

function AppContainer() {
  const [environment, setEnvironment] = useState<EnvironmentResponse | undefined>();
  useEffect(() => {
    (async () => {
      setEnvironment(await loadEnvironment());
    })();
  }, []);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router>
        <UserProvider>
          <Provider store={store}>
            <NewInvestigationProvider>
              <PersistGate loading={<Typography>Loading...</Typography>} persistor={persistor}>
                {environment && (
                  <LDProvider clientSideID={environment.launchDarklyClientId}>
                    <App />
                  </LDProvider>
                )}
              </PersistGate>
            </NewInvestigationProvider>
          </Provider>
        </UserProvider>
      </Router>
    </MuiPickersUtilsProvider>
  );
}

ReactDOM.createRoot(document.getElementById('app')!).render(<AppContainer />);
