import * as rootReducer from 'Services/rootReducer';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistCombineReducers, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

export const history = createBrowserHistory();

export let store;

if (process.env.NODE_ENV === 'production') {
  const persistConfig = {
    key: 'principal',
    storage,
    blacklist: ['form'],
  };

  const reducers = { ...rootReducer };

  store = createStore(
    persistCombineReducers(persistConfig, reducers),
    undefined,
    compose(applyMiddleware(thunk)),
  );
} else {
  const persistConfig = {
    key: 'principal',
    storage,
    debug: true,
    blacklist: ['form', 'agencies', 'records', 'subjectPacketRecords'],
  };

  const reducers = { ...rootReducer };

  store = createStore(
    persistCombineReducers(persistConfig, reducers),
    undefined,
    compose(applyMiddleware(thunk)),
  );
}

export const persistor = persistStore(store);
