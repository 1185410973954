import {
  FloatingArrow,
  FloatingFocusManager,
  type FloatingFocusManagerProps,
  FloatingNode,
  FloatingPortal,
  type OffsetOptions,
  type Placement,
  type UseInteractionsReturn,
  arrow,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useTransitionStyles,
} from '@floating-ui/react';
import $, { type StylixProps } from '@stylix/core';
import { useMediaQuery } from '@uidotdev/usehooks';
import {
  ForceTheme,
  type ForcedTheme,
  mediaQueries,
  useTheme,
} from 'Components/App/modules/InvestigationNew/ui-shared/theme';
import { useObserver } from 'keck/react';
import { type ReactNode, type Ref, createContext, useContext, useEffect, useRef } from 'react';

export type PopoverProps = StylixProps<{
  content: ReactNode | ReactNode[] | ((control: PopoverControl) => ReactNode);
  children:
    | ReactNode
    | ReactNode[]
    | ((
        targetProps: ReturnType<UseInteractionsReturn['getReferenceProps']>,
        control: PopoverControl,
      ) => ReactNode | ReactNode[]);
  placement?: Placement;

  /**
   * True to keep the popover mounted even when closed.
   */
  keepMounted?: boolean;

  /**
   * Specifies options for the floating element's offset from the target element. See floating-ui's OffsetOptions
   * documentation for more information.
   */
  offsetOptions?: OffsetOptions;

  handleFocus?: boolean;
  handleHover?: boolean;
  handleClick?: boolean;
  handleDismiss?: boolean;

  /**
   * Props to pass directly to the FloatingFocusManager component. This component is used to manage focus within the
   * popover content. See https://floating-ui.com/docs/FloatingFocusManager.
   */
  focusProps?: Omit<FloatingFocusManagerProps, 'context' | 'children'>;

  disabled?: boolean;

  /**
   * A control object for opening and closing the popover. Call usePopoverControl to create the value for this prop.
   * This value is a Keck observable that you can control externally. If not provided, the popover will create its own
   * control object.
   */
  control?: PopoverControl;

  /**
   * Forces a theme for the popover styles and content, without forcing the theme on the target element.
   */
  forceTheme?: ForcedTheme;

  wrapperStyles?: StylixProps;

  /**
   * Additional style overrides to apply to the popover content wrapper. This wrapper sets the border, background,
   * shadow, and default content padding.
   */
  contentStyles?: StylixProps;

  contentRef?: Ref<HTMLDivElement>;

  disableArrow?: boolean;

  /**
   * The popover arrow is normally positioned at the center of the target element.
   * Use this prop to position the arrow at the placement edge of the popover.
   */
  staticArrowOffset?: boolean;

  /**
   * The [width, height] of the arrow in pixels. Defaults to [12, 6].
   */
  arrowSize?: [number, number];

  matchTargetWidth?: boolean;

  onOpen?: () => void;
  onClose?: () => void;
}>;

const DEFAULT_ARROW_SIZE = [12, 6];

export function Tooltip(props: PopoverProps) {
  return (
    <Popover
      contentStyles={{ p: '5px 12px', fontWeight: 500, fontSize: 15 }}
      handleHover
      handleFocus
      wrapperStyles={{ pointerEvents: 'none' }}
      {...props}
      focusProps={{
        disabled: true, // Tooltips don't capture focus
        ...props.focusProps,
      }}
    />
  );
}

export interface PopoverControl {
  readonly isOpen: boolean;
  toggleOpen(open?: boolean): void;
}

export function usePopoverControl(isOpen = false): PopoverControl {
  return useObserver({
    isOpen,
    toggleOpen(open?: boolean) {
      this.isOpen = open ?? !this.isOpen;
    },
  });
}

const popoverControlCtx = createContext<PopoverControl | null>(null);

/**
 * Returns the PopoverControl object for the current popover. This will return `null` if the current component
 * is not located within a Popover.
 */
export function useCurrentPopoverControl(): PopoverControl {
  return useObserver(useContext(popoverControlCtx)!);
}

export function Popover(props: PopoverProps) {
  const {
    content,
    children,
    placement = 'bottom',
    control = usePopoverControl(),
    focusProps,
    keepMounted = false,
    handleHover = true,
    handleFocus = true,
    handleClick = false,
    handleDismiss = true,
    offsetOptions,
    staticArrowOffset,
    arrowSize = DEFAULT_ARROW_SIZE,
    disableArrow = false,
    wrapperStyles,
    contentStyles,
    disabled,
    forceTheme,
    matchTargetWidth,
    onOpen,
    onClose,
    contentRef: propsContentRef,
    ...styles
  } = props;

  const { themeColors } = useTheme(forceTheme);
  const isMobile = useMediaQuery(mediaQueries.mobile);

  const [arrowWidth, arrowHeight] = disableArrow ? [0, 0] : arrowSize;

  const nodeId = useFloatingNodeId();

  const arrowRef = useRef(null);
  const { refs, floatingStyles, context, middlewareData } = useFloating({
    open: !disabled && control.isOpen,
    onOpenChange: (open) => {
      control.toggleOpen(open);
    },
    nodeId,
    placement,
    strategy: 'fixed',
    middleware: [
      offsetOptions ? offset(offsetOptions) : !disableArrow && offset(arrowHeight - 2),

      /**
       * The floating element wrapper will be resized to fit the available height. It is up to the content to set its height
       * to 100% and handle scrolling if necessary.
       */
      size({
        apply({ availableHeight, elements }) {
          if (contentRef.current) {
            contentRef.current.style.maxHeight = `${Math.max(100, availableHeight - 5)}px`;
            if (matchTargetWidth)
              contentRef.current.style.minWidth = `${elements.reference.getBoundingClientRect().width}px`;
          }
        },
      }),

      // top/left padding prevent the tooltip from displaying under the left/top sidebars
      flip({
        fallbackStrategy: 'bestFit',
        crossAxis: false,
      }),
      shift({ padding: { top: 5, left: isMobile ? 5 : 50, bottom: 5, right: 5 } }),

      !disableArrow &&
        arrow({
          element: arrowRef,
          padding: 5, // to avoid rounded corners
        }),
    ],
    whileElementsMounted: autoUpdate,
  });

  // Set isOpen to false when the disabled prop becomes true.
  // E.g. If 'disabled' becomes true while the user is hovered over the target element, the hover interaction will not set
  // 'isOpen' to false when the user leaves the element.
  useEffect(() => {
    if (disabled) {
      control.toggleOpen(false);
    }
  }, [disabled]);

  const arrowX = middlewareData.arrow?.x ?? 0;
  const arrowY = middlewareData.arrow?.y ?? 0;
  const transformX = arrowX + arrowWidth / 2;
  const transformY = arrowY + arrowHeight;

  const arrowBorder = 1; //theme === 'light' ? 0 : 1;
  const arrowTotalWidth = arrowWidth + arrowBorder * 2;

  const staticOffset = staticArrowOffset ? 10 : undefined;

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: { open: 100, close: 200 },
    initial: {
      transform: 'scale(0.85)',
      opacity: 0,
    },
    common: ({ placement }) => ({
      transformOrigin: {
        top: `${transformX}px calc(100% + ${arrowHeight}px)`,
        'top-start': `${staticOffset ? staticOffset + arrowTotalWidth / 2 : transformX}px calc(100% + ${arrowHeight}px)`,
        'top-end': `calc(100% - ${staticOffset ? staticOffset + arrowTotalWidth / 2 : transformX}px) calc(100% + ${arrowHeight}px)`,
        bottom: `${transformX}px ${-arrowHeight}px`,
        'bottom-start': `${staticOffset ? staticOffset + arrowTotalWidth / 2 : transformX}px ${-arrowHeight}px`,
        'bottom-end': `calc(100% - ${staticOffset ? staticOffset + arrowTotalWidth / 2 : transformX}px) ${-arrowHeight}px`,
        left: `calc(100% + ${arrowHeight}px) ${transformY}px`,
        'left-start': `calc(100% + ${arrowHeight}px) ${staticOffset ? staticOffset + arrowTotalWidth / 2 : transformY}px`,
        'left-end': `calc(100% + ${arrowHeight}px) calc(100% - ${staticOffset ? staticOffset + arrowTotalWidth / 2 : transformY}px)`,
        right: `${-arrowHeight}px ${transformY}px`,
        'right-start': `${-arrowHeight}px ${staticOffset ? staticOffset + arrowTotalWidth / 2 : transformY}px`,
        'right-end': `${-arrowHeight}px calc(100% - ${staticOffset ? staticOffset + arrowTotalWidth / 2 : transformY}px)`,
      }[placement],
    }),
  });

  const hasOpened = useRef(false);

  useEffect(() => {
    if (isMounted) {
      hasOpened.current = true;
      const t = setTimeout(() => onOpen?.(), 50);
      return () => clearTimeout(t);
    }
    if (hasOpened.current) {
      hasOpened.current = false;
      onClose?.();
    }
  }, [isMounted]);

  const hover = useHover(context, {
    enabled: !disabled && handleHover,
    handleClose: safePolygon(),
  });
  const click = useClick(context, {
    enabled: !disabled && handleClick,
  });
  const dismiss = useDismiss(context, {
    enabled: !disabled && handleDismiss,
  });
  const focus = useFocus(context, {
    enabled: !disabled && handleFocus,
  });

  const contentRef = useRef<HTMLDivElement | null>(null);
  const mergedContentRefs = useMergeRefs([contentRef, props.contentRef]);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, click, dismiss, focus]);

  return (
    <popoverControlCtx.Provider value={control}>
      <FloatingNode id={nodeId}>
        {typeof children === 'function' ? (
          children(
            content ? { ref: refs.setReference as () => void, ...getReferenceProps() } : {},
            control,
          )
        ) : (
          <$.div ref={refs.setReference} {...getReferenceProps()} {...styles}>
            {children}
          </$.div>
        )}

        {!!content && (isMounted || keepMounted) && (
          <FloatingPortal>
            <FloatingFocusManager context={context} {...focusProps}>
              <ForceTheme theme={forceTheme}>
                {/* floating element - unstyled; handles positioning. */}
                <$.div
                  ref={refs.setFloating}
                  z-index={1200} // mui popover is 1300, ui elements like floating sidebars/headers are 1100
                  style={floatingStyles}
                  {...getFloatingProps()}
                  $css={[keepMounted && !isMounted ? { display: 'none' } : {}, wrapperStyles]}
                >
                  {/* transition wrapper - handles open/close scale & fade transition, has background & border */}
                  <$.div
                    relative
                    border-radius={6}
                    border={`1px solid ${themeColors.slate[400]}`}
                    background={themeColors.neutral[90]}
                    color={themeColors.contrast[100]}
                    box-shadow="0 5px 10px #0004"
                    style={transitionStyles}
                    p="8px 12px"
                    $css={[{ 'backdrop-filter': 'blur(8px)' }, contentStyles]}
                    ref={mergedContentRefs}
                    data-label="Popover-content"
                  >
                    {/* scroll wrapper - has default padding, overflow: auto, and max-height set by floating-ui */}
                    {typeof content === 'function' ? content(control) : content}

                    {!disableArrow && (
                      <FloatingArrow
                        ref={arrowRef}
                        context={context}
                        width={arrowWidth}
                        height={arrowHeight}
                        stroke={themeColors.slate[400]}
                        strokeWidth={arrowBorder}
                        tipRadius={2}
                        fill={themeColors.neutral[90]} //color(themeColors.gray[100]).alpha(0.9).string()}
                        staticOffset={staticOffset}
                      />
                    )}
                  </$.div>
                </$.div>
              </ForceTheme>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </FloatingNode>
    </popoverControlCtx.Provider>
  );
}
