import { fade } from '@material-ui/core/styles';
import { EyeColorCodeEnum, RaceEnum, SexEnum } from 'Root/enums';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import pull from 'lodash/pull';
import remove from 'lodash/remove';
import moment from 'moment';
import { isValidPhone } from './commonValidators';

const INTEGER_MAX = 2147483647;
const INTEGER_MIN = 0;
const DEFAULT_RESULT_COUNT_PER_PAGE = 5;
const DEFAULT_SKIP_VALUE = 0;
const DENOMINATIONS = [1, 2, 5, 10, 20, 50, 100];
export const PHONE_KEY_FILTER = /\d|-|\(|\)|\s/;
export const SSN_KEY_FILTER = /\d|-|\s/;
export const MEID_KEY_FILTER = /\d|[A-F]/i;
export const DIGIT_FILTER = /\d/i;

let loadedJiraServiceWidget = false;

function formatPhone(phone) {
  const stripped = (phone || '').replace(/[^\d]/g, '');
  if (isValidPhone(stripped)) {
    const digits = stripped.replace(/(^\+?1)/g, '').split(''); // Remove leading +1 and non-digits
    return `(${digits.slice(0, 3).join('')}) ${digits.slice(3, 6).join('')}-${digits.slice(6).join('')}`;
  }
  return phone;
}
function formatSsn(ssn) {
  const stripped = (ssn || '').replace(/[^\d]/g, '');
  if (stripped.length === 9) {
    const digits = stripped.split('');
    return `${digits.slice(0, 3).join('')}-${digits.slice(3, 5).join('')}-${digits.slice(5).join('')}`;
  }
  return ssn;
}
function formatHeight(height) {
  const heightAsNumber = Number.parseInt(height, 10) || 0;
  if (!heightAsNumber) {
    return 'N/A';
  }
  return `${Math.round(heightAsNumber / 100)}' ${heightAsNumber % 100}"`;
}

function formatRace(race) {
  const raceEnum = Object.values(RaceEnum).find(
    (x) => x.id === race || x.name === race || x.code === race,
  );
  if (raceEnum) {
    return raceEnum.name;
  }
  return race || '';
}

function formatSex(sex) {
  const sexEnum = Object.values(SexEnum).find(
    (x) => x.id === sex || x.name === sex || x.code === sex,
  );
  if (sexEnum) {
    return sexEnum.name;
  }
  return sex || '';
}

function formatEyeColor(eyeColor) {
  const eyeColorEnum = Object.values(EyeColorCodeEnum).find(
    (x) => x.id === eyeColor || x.name === eyeColor || x.code === eyeColor,
  );
  if (eyeColorEnum) {
    return eyeColorEnum.name;
  }
  return eyeColor || '';
}

function formatTitle(title) {
  return title.replace(/\s\s+/g, ' ').trim();
}

function formatTextToDangerousHtml(text) {
  return text && { __html: text.replace(/(?:\r\n|\r|\n)/g, '<br/>') };
}

/**
 * @param {Object<string, { id: number, name: string }} enumObj
 * @param {any} val The ID, name or code that references the enum
 * @param {string} prop The property of the enum to return. Defaults to 'name'
 */
function formatEnum(enumObj, val, prop = 'name', filterBy = 'id') {
  const values = Object.values(enumObj);
  const enumValue = values.find((x) => x[filterBy] === val);
  if (enumValue) {
    return enumValue[prop];
  }
  return '';
}

function formatTimespan(firstDate, lastDate, formatAs = 'seconds', includeUnits = true) {
  if (!firstDate || !lastDate) {
    return null;
  }
  const duration = moment.duration(moment(firstDate).diff(lastDate)).as(formatAs);

  if (includeUnits) {
    return `${duration} ${formatAs}`;
  }

  return duration;
}

function generateKey(pre) {
  return `${pre}_${new Date().getTime()}`;
}

function textToDangerousHtml(text) {
  return {
    __html:
      text && text.length > 0
        ? text.replace(/ /g, '&nbsp;').replace(/(?:\r\n|\r|\n)/g, '<br/>')
        : '',
  };
}

function isNullOrWhiteSpace(text) {
  return text === null || text.match(/^ *$/) !== null;
}

function isCrappyBrowser() {
  return (
    /MSIE (\d+\.\d+);/.test(navigator.userAgent) ||
    window.navigator.userAgent.indexOf('Trident/') > -1
  );
}

const formatKey = (key) => key.replace(/(^.|[A-Z])/g, (v) => ` ${v}`.toUpperCase());

function objectToPropertyList(obj) {
  return fromPairs(map(obj, (value, key) => [formatKey(key), value]).filter((x) => x[0] && x[1]));
}

/**
 * The point of this function is to transform a list of disposition type IDs
 * to the correct properties to get sent to the API. If the
 * disposition type Id of -2, which is the value for 'Any' in the dropdown,
 * then that means that we want investigations with at least 1 disposition.
 * If the user has selected -1, which is the value for 'None' then
 * that means we want investigations with no dispositions.
 *
 * @param {import('Root/dtos.ts').SearchInvestigation} filterValues
 */
function convertDispositionPropertiesForInvestigationSearch(filterValues) {
  if (!filterValues || isNil(filterValues.dispositionTypeIds)) {
    return filterValues;
  }

  const newFilterValues = cloneDeep(filterValues);

  if (!isArray(newFilterValues.dispositionTypeIds)) {
    newFilterValues.dispositionTypeIds = [newFilterValues.dispositionTypeIds];
  }

  remove(newFilterValues.dispositionTypeIds, isNil);

  if (newFilterValues.dispositionTypeIds.includes(-2)) {
    pull(newFilterValues.dispositionTypeIds, -2);
    newFilterValues.hasDisposition = true;
  } else if (newFilterValues.dispositionTypeIds.includes(-1)) {
    pull(newFilterValues.dispositionTypeIds, -1);
    newFilterValues.hasDisposition = false;
  }
  return newFilterValues;
}

function guid() {
  return `${Date.now()}-${Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)}`;
}

/**
 * @param {string} className The name of the class that will be used as the className on a component
 * @param {Color} color The color to use for this button
 * @param {import('@material-ui/styles').CSSProperties} otherRules Other CSS styling
 * @param {'low'|'normal'|'high'} emphasis How much the pulsating should be emphasized
 */
function createPulsatingStyleClass(className, color, otherRules = {}, emphasis = 'normal') {
  return {
    [className]: {
      backgroundColor: color['600'],
      '&:hover': {
        backgroundColor: color['500'],
      },
      animationName: `$${className}Pulse`,
      animationDuration: '2s',
      animationIterationCount: 'infinite',
      ...otherRules,
    },
    [`@keyframes ${className}Pulse`]: {
      from: {
        boxShadow: `0 0 0 0 ${fade(
          color['500'],
          {
            low: 0.25,
            normal: 0.4,
            high: 0.8,
          }[emphasis] || 0.4,
        )}`,
      },
      to: {
        boxShadow: `0 0 10px 30px ${fade(color['500'], 0)}`,
      },
    },
  };
}

const createNumberSequence = (number, includeZero = false) => {
  if (!Number.isNaN(+number)) {
    const res = [...Array(number).keys()].map((v) => (includeZero ? v : v + 1));
    return res;
  }
  return null;
};

const getAgeFromDOB = (dob) => {
  const age = moment().diff(dob, 'years');

  return Number.isNaN(age) ? undefined : age;
};

const asNumber = (value) => Number.parseInt(value, 10) || 0;

/**
 * To-do: Problem with one empty value
 * Will look into it more
 */
const validateHeight = (feetFrom, inchFrom, feetTo, inchTo) => {
  const heightFrom = asNumber(feetFrom, 10) * 12 + asNumber(inchFrom);
  const heightTo = asNumber(feetTo) * 12 + asNumber(inchTo);
  const valid = heightFrom <= heightTo;
  return valid;
};

function getFormatedHeight(height) {
  const heightAsNumber = Number.parseInt(height, 10) || 0;
  if (!heightAsNumber) {
    return { feet: null, inch: null };
  }
  const formatedHeight = { feet: Math.round(heightAsNumber / 12), inch: heightAsNumber % 12 };
  return formatedHeight;
}

const getFileExtension = (fileName) => /[^.]+$/.exec(fileName);

const hasRole = (role) => {
  const user = JSON.parse(localStorage.getItem('user')) || {};
  return user?.roles && role ? user.roles.some((r) => r === role) : false;
};

const getBase64String = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const loadScript = async (src, attributes, callback) => {
  const s = document.createElement('script');
  s.src = src;
  Object.keys(attributes).forEach((k) => {
    s.setAttribute(k, attributes[k]);
  });

  s.async = true;
  s.onload = callback;
  document.body.appendChild(s);
};

const renderSupportButton = async (config) => {
  if (!loadedJiraServiceWidget) {
    await loadScript(
      config.jiraSupportWidgetSourceUrl,
      {
        'data-jsd-embedded': '',
        'data-key': config.jiraSupportKey,
        'data-base-url': config.jiraSupportBaseUrl,
      },
      () => {
        const domContentLoadedEvent = new Event('DOMContentLoaded', {
          bubbles: true,
          cancelable: true,
        });

        window.document.dispatchEvent(domContentLoadedEvent);
        loadedJiraServiceWidget = true;
      },
    );
  }
};

export {
  INTEGER_MAX,
  INTEGER_MIN,
  DEFAULT_RESULT_COUNT_PER_PAGE,
  DEFAULT_SKIP_VALUE,
  DENOMINATIONS,
  formatTextToDangerousHtml,
  formatHeight,
  formatEyeColor,
  formatRace,
  formatSex,
  formatTitle,
  generateKey,
  formatPhone,
  formatSsn,
  formatEnum,
  formatTimespan,
  textToDangerousHtml,
  isNullOrWhiteSpace,
  objectToPropertyList,
  convertDispositionPropertiesForInvestigationSearch,
  guid,
  createPulsatingStyleClass,
  isCrappyBrowser,
  getAgeFromDOB,
  createNumberSequence,
  validateHeight,
  getFormatedHeight,
  getFileExtension,
  hasRole,
  getBase64String,
  renderSupportButton,
};

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{8,})/;
export const unique = (array = []) => [...new Set(array)];
