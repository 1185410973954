/**
 * Utilities to convert durations to milliseconds and vice versa.
 */
export const ms = {
  from: {
    hours(h: number) {
      return h * 60 * 60 * 1000;
    },
    minutes(m: number) {
      return m * 60 * 1000;
    },
    seconds(s: number) {
      return s * 1000;
    },
  },
  to: {
    hours(ms: number) {
      return ms / 60 / 60 / 1000;
    },
    minutes(ms: number) {
      return ms / 60 / 1000;
    },
    seconds(ms: number) {
      return ms / 1000;
    },
  },
};
