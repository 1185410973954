import { faSquare } from '@fortawesome/pro-regular-svg-icons/faSquare';
import { faCircle, faDotCircle, faSquareMinus } from '@fortawesome/pro-solid-svg-icons';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import $, { type StylixHTMLProps, type StylixProps } from '@stylix/core';
import { IconButton } from 'Components/App/modules/InvestigationNew/ui-shared/IconButton';
import { palette, useTheme } from 'Components/App/modules/InvestigationNew/ui-shared/theme';
import { useFormContext } from 'keck-forms';
import type React from 'react';
import { useCallback } from 'react';
import { useId } from 'react';

export type CheckboxProps<TValue> = StylixProps & {
  value?: TValue;
  field?: string;
  variant?: 'checkbox' | 'toggle' | 'radio';
  large?: boolean;
  /**
   * The behavior of the checkbox when clicked. Only used when `field` is provided.
   * If 'set', the checkbox will add/remove `value` in the Set defined by `field`.
   * If 'toggle', the checkbox will toggle the given field between TValue and null.
   */
  behavior?: 'set' | 'toggle';
  checked?: boolean;
  partial?: boolean;
  onChange?(value: TValue | null): void;
  disabled?: boolean;
  label?: React.ReactNode;
  labelProps?: StylixHTMLProps<'label'>;
};

export function Checkbox<TValue>(props: CheckboxProps<TValue>) {
  const {
    value,
    field,
    variant = 'checkbox',
    large,
    behavior,
    checked: checkedProp,
    partial,
    onChange,
    disabled,
    label,
    labelProps,
    ...styles
  } = props;

  const { themeColors } = useTheme();

  const form = field ? useFormContext() : null;
  const formField = field ? form?.field(field) : null;
  const formValue = formField ? formField.value : null;

  const checked =
    checkedProp ?? (formValue instanceof Set ? formValue.has(value) : formValue === value);

  const id = useId();

  const handleClickCheckbox = useCallback(
    function handleClickCheckbox() {
      if (form && formField) {
        if (formValue instanceof Set) {
          checked ? formValue.delete(value) : formValue.add(value);
        } else if (variant === 'checkbox' || variant === 'toggle') {
          formField.value = checked ? null : value;
        } else if (variant === 'radio') {
          formField.value = value;
        }
        form.validate();
      }
      onChange?.(checked ? null : (value ?? null));
    },
    [onChange, checked],
  );

  return (
    <$.div inline-flex cursor="pointer" align-items="center" {...styles}>
      {(variant === 'checkbox' || variant === 'radio') && (
        <$.div flex="0 0 auto" width={20} height={20} relative>
          <IconButton
            icon={
              variant === 'checkbox'
                ? partial
                  ? faSquareMinus
                  : checked
                    ? faCheckSquare
                    : faSquare
                : checked
                  ? faCircle
                  : faDotCircle
            }
            size={20}
            abs-center
            iconProps={{ marginTop: -1, color: themeColors.sky[600] }}
            id={`checkbox-${id}`}
            disabled={disabled}
            onClick={handleClickCheckbox}
          />
        </$.div>
      )}

      {variant === 'toggle' && (
        <$.div
          className="Checkbox-toggle"
          flex="0 0 auto"
          width={large ? 50 : 36}
          height={large ? 28 : 24}
          padding={2}
          border={`1px solid ${themeColors.contrast[20]}`}
          bg={checked ? themeColors.sky[600] : themeColors.contrast[5]}
          border-radius={30}
          transition="background-color 0.3s"
          tabIndex={0}
          id={`checkbox-${id}`}
          onClick={handleClickCheckbox}
          $css={[
            {
              '&:focus-visible': {
                outline: `3px solid ${themeColors.sky[100]}`,
              },
              '&:hover': {
                backgroundColor: checked ? themeColors.sky[700] : themeColors.contrast[10],
              },
            },
          ]}
        >
          <$.div
            width={large ? 22 : 20}
            height={large ? 22 : 20}
            border={checked ? 'none' : `1px solid ${themeColors.contrast[15]}`}
            bg={checked ? palette.white[100] : themeColors.contrast[10]}
            border-radius={13}
            transition="all 0.2s"
            transform={`translateX(${checked ? (large ? 22 : 12) : 0}px)`}
          />
        </$.div>
      )}

      {!!label && (
        <$.label
          cursor="pointer"
          htmlFor={`checkbox-${id}`}
          p="0px 0 0 12px"
          mt={-1}
          line-height={1.3}
          // onClick={handleClickCheckbox}
          color={themeColors.contrast[100]}
          {...labelProps}
          $css={[
            disabled && {
              color: themeColors.contrast[60],
              pointerEvents: 'none',
            },
            labelProps?.$css,
          ]}
        >
          {label}
        </$.label>
      )}
    </$.div>
  );
}
