import PropTypes from 'prop-types';
import React from 'react';

/**
  This creates a set of components that can be used to easily share and update a value across the
  app. Note that the value is stored locally in a component's state and not persisted across page
  loads.

  The return value is an object with the following properties (see below for usage examples)
  {
    // The Component that stores the shared value. This component needs to be placed at the top of
    // the component hierarchy where you want the value to be shared.
    SharedValueProvider,

    // A component that gets the current shared value. It is passed as the parameter to the
    // function that is passed as its only child.
    SharedValueConsumer,

    // A HOC that provides the shared value and its setter as props. You can set the names
    // of the value and the setter function props using the parameters.
    withSharedValue(valuePropName, setterPropName),

    // A component that will set the current shared value when rendered.
    SetSharedValue,
  }

  Usage:
  Call the function and export the resulting properties that you need to properly named values:

    export const {
      withSharedValue: withMySharedValue,
      SharedValueProvider: MySharedValueProvider,
      SharedValueConsumer: MySharedValueConsumer,
      SetSharedValue: SetMySharedValue,
    } = createSharedValue('default value');

  You must place the `SharedValueProvider` at the highest
  point the hierarchy where you want the value to be shared:

    import { MySharedValueProvider } from './mySharedValue'
    ...
    <MySharedValueProvider>
      ... you can use <SharedValueConsumer> anywhere under here
    </MySharedValueProvider>

  Use <SharedValueConsumer> to use the shared value in a render function:
    render() {
      return (
        <SharedValueConsumer>
          {value => (
            <p>for example: {value}</p>
          )}
        </SharedValueConsumer>
      )
    }

  Use <SetSharedValue> to set the value whenever it is rendered:
    <SomeComponent>
      <SetSharedValue value="new value" />
    </SomeComponent>

  Use withSharedValue as a HOC to receive the value and its setter as props:
    class MyComponent extends React.Component {
      componentDidMount() {
        this.props.setMySharedValue('for example');
      }

      render() {
        return (
          <p>for example: {this.props.mySharedValue}</p>
        )
      }
    }
    export withMySharedValue('mySharedValue', 'setMySharedValue')(MyComponent);
 */
export default function createSharedValue(defaultValue = undefined) {
  const sharedValueContext = React.createContext(defaultValue);

  class SharedValueProvider extends React.Component {
    static propTypes = {
      children: PropTypes.any.isRequired,
    };

    state = {
      value: undefined,
    };

    setValue = (value) => {
      this.setState({ value });
    };

    render() {
      return (
        <sharedValueContext.Provider value={{ value: this.state.value, setValue: this.setValue }}>
          {this.props.children}
        </sharedValueContext.Provider>
      );
    }
  }

  const withSharedValue =
    (setter = 'setValue', getter = 'value') =>
    (Component) =>
    (props) => (
      <sharedValueContext.Consumer>
        {(value) => (
          <Component {...{ [getter]: value.value, [setter]: value.setValue }} {...props} />
        )}
      </sharedValueContext.Consumer>
    );

  const SetSharedValue = withSharedValue()(
    class extends React.Component {
      static propTypes = {
        value: PropTypes.any.isRequired,
        setValue: PropTypes.func.isRequired,
      };

      componentDidMount() {
        this.props.setValue(this.props.value);
      }

      render() {
        return null;
      }
    },
  );

  return {
    withSharedValue,
    SetSharedValue,
    SharedValueProvider,
    SharedValueConsumer: sharedValueContext.Consumer,
  };
}
