import { useDebugValues } from 'Components/useDebugValues';
import { ms } from 'Services/util/time';
import throttle from 'lodash/throttle';
import moment from 'moment';
import type React from 'react';
import { useCallback } from 'react';
import { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { hoistStatics, wrapDisplayName } from 'recompose';
import { useUser } from '../withUser';

// This value sets how long the user can be inactive before the PIN modal appears.
// Don't change this value for testing; use the debugger values instead (see useDebugger.tsx).
const SESSION_IDLE_LOCK = ms.from.minutes(30);

interface SessionLockContext {
  lastActivity: Date;
  updateLastActivity: () => void;
  sessionLocked: boolean;
  sessionLockedOnStartup: boolean;
  setSessionLocked: (locked: boolean) => void;
  getTimeLeft: () => number;
}

const sessionLockContext = createContext<SessionLockContext | undefined>(undefined);

export function SessionLockProvider(props: { children: React.ReactNode }) {
  const user = useUser();
  const [debugValues] = useDebugValues();

  // Function to get the time left in the user's session (in ms).
  const getTimeLeft = useCallback(() => moment(user.exp).diff(moment(), 'ms'), [user.exp]);

  // Function to refresh the user's session. Throttled to run at most every 5 minutes (or 5 seconds when debugger is enabled).
  const throttledRefreshSession = useCallback(
    throttle(
      async () => {
        try {
          await user.refreshSession();
        } catch (e) {
          // Because this function is throttled and run at some arbitrary point in the future,
          // any error is lost if we don't catch it and log it here
          console.error(e);
        }
      },
      debugValues?.session ? ms.from.seconds(5) : ms.from.minutes(5),
      { leading: true, trailing: true },
    ),
    [user.refreshSession],
  );

  // Function to update the last activity time. Throttled to run at most every 2 seconds.
  const updateLastActivity = useCallback(
    throttle(
      async () => {
        setContextValue((c) => ({ ...c, lastActivity: new Date() }));
        await throttledRefreshSession();
      },
      ms.from.seconds(2),
      { leading: true, trailing: true },
    ),
    [throttledRefreshSession],
  );

  const [context, setContextValue] = useState({
    lastActivity: new Date(),
    updateLastActivity,
    sessionLocked: false,
    sessionLockedOnStartup: !!localStorage.getItem('sessionLocked'),
    setSessionLocked: (locked: boolean) => {
      if (locked) localStorage.setItem('sessionLocked', '1');
      else localStorage.removeItem('sessionLocked');
      setContextValue((c) => ({ ...c, sessionLocked: locked }));
    },
    getTimeLeft,
  });

  // Some of the functions in the context object are callbacks that depend on the user object, so we need to update
  // the context object when they change.
  useEffect(() => {
    setContextValue((c) => ({
      ...c,
      getTimeLeft,
      updateLastActivity,
    }));
  }, [getTimeLeft, updateLastActivity]);

  // Once per second, perform lock/timeout checks
  useEffect(() => {
    const i = setInterval(() => {
      // Log debugging info if debugValues are set
      if (debugValues?.session) {
        console.debug({
          lastActivity: context.lastActivity,
          expiration: context.getTimeLeft() / 1000,
          idleLock: moment(
            context.lastActivity.valueOf() + (debugValues.session?.idleLock || SESSION_IDLE_LOCK),
          ).diff(moment(), 'seconds'),
        });
      }

      if (user.isAuthenticated) {
        // If the user has been idle for more than SESSION_IDLE_LOCK, lock the session to request PIN (see AskSessionLockPINModal.jsx)
        if (
          !context.sessionLocked &&
          context.lastActivity &&
          moment().isSameOrAfter(
            moment(context.lastActivity).add(debugValues?.session?.idleLock || SESSION_IDLE_LOCK),
          )
        ) {
          context.setSessionLocked(true);
        }

        // If there is no time left in the session, log the user out
        const timeLeft = context.getTimeLeft();
        if (timeLeft <= 0) {
          user.logOff('Your session has timed out. Please log in again.');
        }
      }
    }, 1000);

    return () => clearInterval(i);
  }, [debugValues?.session, context, user.isAuthenticated]);

  // If the session is locked when this component mounts, log the user out before rendering
  useLayoutEffect(() => {
    if (context.sessionLockedOnStartup) {
      user.logOff('Your session has timed out. Please log in again.');
    }
  }, []);

  // Set up user activity listeners
  useEffect(() => {
    if (!user.isAuthenticated || context.sessionLocked) return;

    window.addEventListener('mousemove', context.updateLastActivity);
    window.addEventListener('touchstart', context.updateLastActivity);
    window.addEventListener('keypress', context.updateLastActivity);

    return () => {
      window.removeEventListener('mousemove', context.updateLastActivity);
      window.removeEventListener('touchstart', context.updateLastActivity);
      window.removeEventListener('keypress', context.updateLastActivity);
    };
  }, [!!user.isAuthenticated, context.sessionLocked, context.updateLastActivity]);

  // Update last activity as soon as user authenticates
  useEffect(() => {
    if (!user.isAuthenticated) return;
    context.updateLastActivity();
  }, [!!user.isAuthenticated]);

  // Make sure to remove the session lock when the user logs out
  useEffect(() => {
    window.addEventListener('logout', () => {
      context.setSessionLocked(false);
    });
  }, []);

  return (
    <sessionLockContext.Provider value={context}>{props.children}</sessionLockContext.Provider>
  );
}

export function useSessionLockContext() {
  const ctx = useContext(sessionLockContext);
  if (!ctx) {
    throw new Error('useSessionLockContext must be used within a SessionLockProvider');
  }
  return ctx;
}

/**
 * Legacy method to add the session lock context to a component
 */
export const withSessionLockContext = hoistStatics((Component) => {
  const WithSessionLockContext = (props) => {
    const context = useSessionLockContext();
    return <Component {...props} sessionLockContext={context} />;
  };
  WithSessionLockContext.displayName = wrapDisplayName(Component, 'withSessionLockContext');
  return WithSessionLockContext;
});
