import { EnvironmentResponse, GetEnvironment } from 'Root/dtos.ts';
import client from './client';

const environment = new EnvironmentResponse();

export const loadEnvironment = async () => {
  const response = await client.get(new GetEnvironment());
  return Object.assign(environment, response);
};

export default environment;
