import { CircularProgress } from '@material-ui/core';
import { Grid } from '@material-ui/core';

export default function ScreenLoader() {
  return (
    <Grid container justify="center" alignItems="center" alignContent="center" direction="column">
      <Grid item>
        <CircularProgress size={100} />
      </Grid>
    </Grid>
  );
}
