import { newInvestigationsEnabled } from 'Components/App/NewInvestigationsToggle';
import type React from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

const newInvestigationsContext = createContext({
  enabled: false,
  setEnabled: (enabled: boolean) => {},
});

export function NewInvestigationProvider(props: { children: any }) {
  const [enabled, setEnabled] = useState(newInvestigationsEnabled());

  const state = useMemo(
    () => ({
      enabled,
      setEnabled,
    }),
    [enabled],
  );

  return <newInvestigationsContext.Provider value={state} {...props} />;
}

export function useNewInvestigationsContext() {
  return useContext(newInvestigationsContext);
}

export function withNewInvestigationsContext() {
  return (Component: React.ComponentType) => {
    return (props: any) => {
      const newInvestigationsContext = useNewInvestigationsContext();
      return <Component {...props} newInvestigationsContext={newInvestigationsContext} />;
    };
  };
}
