import * as Sentry from '@sentry/browser';
import { JsonServiceClient } from '@servicestack/client';
import UserProvider from 'Components/UserProvider';
import { getDebugValues } from 'Components/useDebugValues';
import { store } from 'Root/store/configureStore';
import { createError } from 'Services/error/actions';
import get from 'lodash/get';
import { isCrappyBrowser } from './constants';
import eventEmitter from './events';
import { seClient } from './serverEventsClient';

const hostname = window.location.host || 'localhost:5050';
const protocol = window.location.protocol || 'http:';

/**
 * The API URL to use for requests. Locally, when using the proxy to avoid CORS issues, this will be the vite dev server URL.
 * Otherwise, this will always point to the LENSS service URL directly.
 */
export const API_URL =
  import.meta.env.VITE_ENABLE_PROXY || !import.meta.env.VITE_SERVICE_URL
    ? // If the Vite proxy is enabled or no service URL is set, just use the current window's host/port.
      `${protocol}//${hostname}`
    : // Otherwise use the provided service URL.
      import.meta.env.VITE_SERVICE_URL;

/**
 * Unlike API_URL, SERVICE_URL *always* points to the underlying LENSS API service URL (never the vite proxy).
 * This only applies to local development. In other environments, SERVICE_URL and API_URL will always be the same.
 */
export const SERVICE_URL = import.meta.env.VITE_SERVICE_URL || API_URL;

const client = new JsonServiceClient(API_URL);
client.manageCookies = true;
const businessRuleErrorHandlers = {
  E1001: () => {
    eventEmitter.emit('access-denied');
  },
};

client.exceptionFilter = async (/** @type {Response} */ res, error) => {
  if (error?.message === 'Failed to fetch') {
    // If something failed to fetch, we don't need popups and logging for it - something is obviously wrong
    return;
  }

  if (
    get(error, 'responseStatus.message') ===
    'You account or agency is currently inactive or suspended.'
  ) {
    await UserProvider.instance.logOff('You account is currently inactive or suspended.');
    return;
  }

  if (
    res?.status === 401 ||
    (get(error, 'type') === 'RefreshTokenException' &&
      get(error, 'responseStatus.message') === 'Token has expired')
  ) {
    await UserProvider.instance.logOff('Your session has expired, please log in again');
    return;
  }

  if (get(error, 'type') === 'RefreshTokenException') {
    await UserProvider.instance.logOff(
      'There was a problem with your session, please log in again',
    );
    return;
  }

  const errorData = get(error, 'responseStatus') || {};

  if (res?.status !== 401) {
    Sentry.addBreadcrumb({
      message: `Received exception from ${res?.url}`,
      category: 'client.error',
      data: errorData,
      type: 'http',
      level: Sentry.Severity.Error,
    });

    Sentry.withScope((scope) => {
      scope.setExtras(errorData);
      Sentry.captureException(
        Object.assign(new Error(get(error, 'responseStatus.message', 'Unspecified error')), {
          name: 'ClientRequestException',
        }),
      );
    });
  }

  // If this was a BusinessRuleException
  if (
    error?.responseStatus &&
    error.responseStatus.message === 'An access violation has occurred. See the errors for details.'
  ) {
    const {
      responseStatus: { errorCode },
    } = error;
    const handler = businessRuleErrorHandlers[errorCode];
    if (handler) {
      handler();
      return;
    }
  }
  store.dispatch(createError(error));
};

client.requestFilter = (req) => {
  if (req.method === 'GET' && isCrappyBrowser()) {
    const url = new URL(req.url);
    url.searchParams.append('ts', new Date().getTime());
    req.url = url.href;
  }
};

client.responseFilter = (response) => {
  Sentry.addBreadcrumb({
    message: `Received client response from ${response.url}`,
    category: 'client.response',
    data: {
      status: response.status,
      statusText: response.statusText,
    },
    type: 'http',
  });

  // Reconnect if we had to refresh our JWT.
  // This happens when the browser opens and the SSE client tries to connect and gets a 401.
  if (
    response.url.includes('GetAccessToken') &&
    seClient?.eventSource.readyState === EventSource.CLOSED
  ) {
    seClient.reconnectServerEvents();
  }

  const sessionExpiration = +response.headers.get('X-Session-Expiration');

  if (sessionExpiration) {
    UserProvider.instance.saveUser({
      exp: new Date(
        getDebugValues()?.session?.expiration
          ? Date.now() + getDebugValues().session.expiration
          : sessionExpiration * 1000,
      ),
    });
  }
};

export const setToken = (bearerToken, refreshToken) => {
  client.bearerToken = bearerToken;
  client.refreshToken = refreshToken;
};

export default client;
