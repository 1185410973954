import type { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import $, { type StylixProps } from '@stylix/core';

export type IconProps = StylixProps & {
  icon: IconDefinition;
  iconProps?: Partial<FontAwesomeIconProps>;
  size: number;
  className?: string;
};

export function Icon(props: IconProps) {
  const { icon, size, iconProps, ...styles } = props;

  return (
    <$
      $el={<FontAwesomeIcon icon={icon} {...iconProps} />}
      width={size}
      height={size}
      {...styles}
    />
  );
}
