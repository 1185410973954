export default (state = {}, action = undefined) => {
  switch (action.type) {
    case 'DATATABLE_SAVE_FILTERS':
      return {
        ...state,
        [action.key]: action.filters,
      };
    default:
  }
  return state;
};
