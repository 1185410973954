import { grey } from '@material-ui/core/colors';
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import createSharedValue from 'Components/shared/SharedValue';
import color from 'color';
import merge from 'lodash/merge';

export const {
  withSharedValue: withSectionTheme,
  SharedValueProvider: SectionThemeProvider,
  SharedValueConsumer: SectionThemeConsumer,
  SetSharedValue: SetSectionTheme,
} = createSharedValue('general');

const baseTheme = {
  overrides: {
    MuiDrawer: {
      docked: {
        flex: '0 0 auto',
        height: '100%',
      },
    },
  },
  drawerWidth: 240,
};

const darkTheme = {
  palette: {
    secondary: {
      ...lightBlue,
      contrastText: '#FFF',
    },
    error: red,
    type: 'dark',

    contrast: {
      lowest: color('#FFF').fade(0.95).toString(),
      lower: color('#FFF').fade(0.85).toString(),
      low: color('#FFF').fade(0.5).toString(),
      high: color('#FFF').fade(0.3).toString(),
      higher: color('#FFF').fade(0.1).toString(),
      highest: '#FFF',
    },

    background: {
      default: red[400], // Shouldn't be used, if you see red, set a background color!
      page: grey[900],
      paper: color(grey[900]).lighten(0.6).toString(),
      sidebar: grey[800],
    },

    general: {
      header: darken(lightBlue[900], 0.3),
      headerAlt: darken(lightBlue[900], 0.5),
      highlight: color(lightBlue[600]).fade(0.85).toString(),
      highlightStrong: color(lightBlue[800]).fade(0.7).toString(),
      ...lightBlue,
    },
  },
};

const lightTheme = {
  palette: {
    secondary: blue,
    error: red,
    type: 'light',

    contrast: {
      lowest: color('#000').fade(0.96).toString(),
      lower: color('#000').fade(0.9).toString(),
      low: color('#000').fade(0.7).toString(),
      high: color('#000').fade(0.3).toString(),
      higher: color('#000').fade(0.1).toString(),
      highest: '#000',
    },

    background: {
      default: red[400], // Shouldn't be used, if you see red, set a background color!
      page: grey[100],
      paper: grey[50],
      sidebar: grey[300],
    },

    general: {
      header: lightBlue[900],
      headerAlt: darken(lightBlue[900], 0.25),
      highlight: color(lightBlue[200]).fade(0.85).toString(),
      highlightStrong: color(lightBlue[50]).fade(0.2).toString(),
      ...lightBlue,
    },
  },
};

export default function createTheme(userTheme = 'light', sectionTheme = undefined) {
  const themeToUse = merge({}, baseTheme, userTheme === 'light' ? lightTheme : darkTheme);
  const section = themeToUse.palette[sectionTheme] || themeToUse.palette.general;
  merge(themeToUse, {
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: undefined,
          background: themeToUse.palette.background.paper,
        },
      },
      MuiButton: {
        textPrimary: {
          color: section[500],
        },
        colorInherit: {
          '&$disabled': {
            color: 'inherit !important',
            opacity: 0.4,
          },
        },
      },
      MuiIconButton: {
        colorInherit: {
          '&$disabled': {
            color: 'inherit !important',
            opacity: 0.4,
          },
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: section.header,
        },
      },
      MuiChip: {
        root: {
          backgroundColor: themeToUse.palette.contrast.lower,
        },
      },
      MuiLink: {
        root: {
          color: section.main,
        },
      },
      MuiSnackbarContent: {
        root: {
          backgroundColor: grey[900],
          color: grey[50],
        },
      },
      MuiTypography: {
        root: {
          color: themeToUse.palette.contrast.highest,
        },
      },
    },
    palette: {
      primary: {
        ...section,
        contrastText: '#FFF',
      },
      section,
    },
  });
  return createMuiTheme(themeToUse);
}
