import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';

export const tracker = import.meta.env.VITE_OPENREPLAY_DISABLED
  ? null
  : new Tracker({
      projectKey: 'IgpWbJmkivvNk4LEANXv',
      ingestPoint: 'https://openreplay.lenss.net/ingest',
      __DISABLE_SECURE_MODE: true,
    });

tracker?.use(
  trackerAssist({
    callConfirm: true,
    controlConfirm: true,
  }),
);
