import type { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import type { StylixHTMLProps } from '@stylix/core';
import $ from '@stylix/core';
import {
  type PopoverProps,
  Tooltip,
} from 'Components/App/modules/InvestigationNew/ui-shared/Popover';
import { palette, useTheme } from 'Components/App/modules/InvestigationNew/ui-shared/theme';
import { type ReactNode, forwardRef } from 'react';
import { Icon, type IconProps } from './Icon';

export type IconButtonProps = StylixHTMLProps<'button'> & {
  icon: IconDefinition;
  /**
   * An additional label to show beside the button.
   */
  label?: ReactNode;
  labelProps?: StylixHTMLProps<'label'>;
  size?: number;
  tooltip?: ReactNode;
  tooltipProps?: Partial<PopoverProps>;
  disabled?: boolean;
  /**
   * Conditionally disables the tooltip. This is better than conditionally setting the `tooltip` property directly,
   * because it allows the tooltip to properly animate in and out if this changes.
   */
  disableTooltip?: boolean;
  /**
   * Permanently enables the hover styles. This is useful when an outer container has a hover effect that displays the button.
   */
  alwaysHover?: boolean;
  badge?: number;
  iconProps?: Partial<IconProps>;
  className?: string;
};

/**
 * Renders a round icon button, optionally with a label next to it, and a basic tooltip.
 */
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const {
    icon,
    label,
    labelProps,
    onClick,
    iconProps,
    size = 24,
    tooltip,
    tooltipProps,
    disabled,
    disableTooltip,
    alwaysHover,
    badge,
    $css,
    ...styles
  } = props;

  const { themeColors } = useTheme();

  const renderButton = (props?: Record<string, unknown>) => {
    return (
      <$.button
        type="button"
        display="inline-flex"
        align-items="stretch"
        justify-content="center"
        padding={0}
        border={0}
        outline={0}
        color={themeColors.sky[950]}
        background="none"
        cursor={disabled ? 'default' : 'pointer'}
        z-index={0}
        disabled={disabled}
        tabIndex={disabled ? -1 : 0}
        onClick={onClick}
        {...props}
        {...styles}
        $css={[
          {
            [alwaysHover ? '& .button' : '&:hover:not(:disabled) .button']: {
              backgroundColor: themeColors.contrast[10],
              transition: 'background 0.1s',
            },
            '&:active:not(:disabled) .button': {
              backgroundColor: themeColors.contrast[20],
              transition: 'background 0.1s',
            },
            '&:focus-visible:not(:disabled) .button': {
              borderColor: themeColors.contrast[10],
            },
            '&:disabled': {
              opacity: 0.5,
            },
          },
          $css,
        ]}
      >
        <$.span
          className="button"
          display="inline-flex"
          align-items="center"
          justify-content="center"
          border="2px solid transparent"
          outline="none"
          height={size + 20}
          min-width={size + 20}
          borderRadius={size + 20}
          relative
          transition="background 0.3s"
        >
          <Icon icon={icon} size={size} relative z-index={1} {...iconProps} />
          {badge !== undefined && (
            <$.span
              absolute
              top={0}
              right={0}
              z-index={2}
              background={palette.sky[600]}
              color={palette.white[100]}
              border-radius={10}
              height={16}
              min-width={16}
              font-weight={700}
              font-size={12}
              p="0 4px"
            >
              {badge}
            </$.span>
          )}
        </$.span>

        {label && (
          <$.span
            display="flex"
            align-items="center"
            font-weight={500}
            pl={8}
            font-size={16}
            {...labelProps}
          >
            {label}
          </$.span>
        )}
      </$.button>
    );
  };

  return tooltip ? (
    <Tooltip content={tooltip} disabled={disabled || disableTooltip} {...tooltipProps}>
      {renderButton}
    </Tooltip>
  ) : (
    renderButton({ ref })
  );
});
