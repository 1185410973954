export const ERROR_OCCURRED = 'ERROR_OCCURRED';
export const ERROR_CLEARED = 'ERROR_CLEARED';

export const createError = (error) => ({
  type: ERROR_OCCURRED,
  data: error,
});

export const clearError = () => ({
  type: ERROR_CLEARED,
});
