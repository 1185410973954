import { CreateConversation, CreateMessage, GetConversation, SearchConversations } from 'Root/dtos';
import client from 'Services/client';
import { seClient } from 'Services/serverEventsClient';
import moment from 'moment';

class TestChatService {
  init() {
    seClient.registerHandler('ConversationResponse', (conversation) => {
      console.debug('New conversation started that includes you ', conversation);
      seClient.subscribeToChannels(conversation.channel);
    });
    seClient.registerHandler('MessageResponse', (msg) => {
      console.debug('New message received ', msg);
    });
  }

  async startConversation(userIds = []) {
    const conversation = await client.post(
      new CreateConversation({
        userIds,
      }),
    );
    console.info(
      'Conversation started with ',
      conversation.participants.map((x) => x.name),
    );
    return conversation;
  }

  async sendMessage(conversationId, content) {
    const message = await client.post(
      new CreateMessage({
        content,
        conversationId,
        userSentAt: moment.utc().toJSON(),
      }),
    );
    console.info('Message sent ', message);
  }

  async getConversations() {
    const conv = await client.get(new SearchConversations());
    console.info(conv.results);
  }

  async getConversationByConversationId(conversationId) {
    const conv = await client.get(new GetConversation({ conversationId }));
    console.info(conv);
  }

  async getServerEventsClient() {
    return seClient;
  }
}

window.chatService = new TestChatService();
