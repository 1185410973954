const initialState = {
  errorDialogVisible: false,
  errorDialogTitle: 'Error',
  errorDialogText: '',
};

const error = (state = initialState, action = undefined) => {
  switch (action.type) {
    case 'ERROR_OCCURRED': {
      const errorObj = action.data;

      const hasErrorArray =
        errorObj.responseStatus?.errors && errorObj.responseStatus.errors.length > 0;

      const errorText = hasErrorArray
        ? errorObj.responseStatus.errors.map((e) => `${e.errorCode || 'Error'} - ${e.message}<br/>`)
        : errorObj.message || errorObj.responseStatus.message;
      return {
        ...state,
        errorDialogVisible: true,
        errorDialogText: errorText,
      };
    }
    case 'ERROR_CLEARED':
      return initialState;
    default:
      return state;
  }
};

export default error;
