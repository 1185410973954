import React, { useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';

interface ShellValues {
  title: string;
  headerContent: React.ReactNode;
  bodyPadding: boolean;
  flexColumn: boolean;
}

interface ShellValuesContext {
  /**
   * The stack consists of an array of shell values. Each usage of `useShellValues()` will push its entry onto the stack,
   * and the resolved shell values will be the result of merging all of the values in the stack. This allows
   * "deeper" React elements to override values set by higher ones.
   */
  stack: ShellValues[];

  /**
   * Because components modify the stack by mutating their references, we need a way to trigger a re-render of the
   * context provider when the stack changes.
   */
  triggerRerender: () => void;

  headerHeight: number;
  setHeaderHeight: (valueOrSetter: number | ((prevState: number) => number)) => void;
}

const shellValuesContext = React.createContext(null! as ShellValuesContext);

/**
 * Context provider wrapper for the shell values. This should be rendered once at the top level of the app.
 */
export function ShellValuesProvider(props: { children: React.ReactNode }) {
  const [headerHeight, setHeaderHeight] = useState(0);
  const stack = useRef<ShellValues[]>([
    {
      title: 'LENSS',
      headerContent: null,
      bodyPadding: true,
      flexColumn: false,
    },
  ]);

  const [rerender, triggerRerender] = useState({});

  const contextValue = useMemo<ShellValuesContext>(
    () => ({
      stack: stack.current,
      triggerRerender: () => triggerRerender({}),
      headerHeight,
      setHeaderHeight,
    }),
    [headerHeight, rerender],
  );

  return (
    <shellValuesContext.Provider value={contextValue}>{props.children}</shellValuesContext.Provider>
  );
}

export function useResolvedShellValues(): ShellValues {
  const { stack } = React.useContext(shellValuesContext);

  return stack.reduce((acc, val) => {
    return Object.assign(acc, val);
  }, {} as ShellValues);
}

/**
 * Sets shell values for the current component. This will set the shell values when the component mounts and remove them
 * when the component unmounts.
 *
 * @param values The shell values to set.
 */
export function useShellControls(values: Partial<ShellValues>) {
  // The valuesRef is a stable reference to an object that mirrors the values passed in. The valuesRef is updated
  // using Object.assign whenever the values change.
  const valuesRef = useRef(Object.assign({}, values) as ShellValues);

  const { stack, triggerRerender } = React.useContext(shellValuesContext);

  // When component mounts, push the values onto the stack.
  useLayoutEffect(() => {
    stack.push(valuesRef.current);
    triggerRerender();

    // When the component unmounts, remove the values from the stack.
    return () => {
      const index = stack.indexOf(valuesRef.current);
      stack.splice(index, 1);
      triggerRerender();
    };
  }, []);

  // When the values change, assign the new values to the valuesRef and trigger a re-render.
  useLayoutEffect(() => {
    Object.assign(valuesRef.current, values);
    triggerRerender();
  }, [
    values.bodyPadding,
    React.isValidElement(values.headerContent) ? values.headerContent?.key : values.headerContent,
    values.title,
  ]);
}

/**
 * This is an alternative way to provide updates to the shell header.
 * Use this component to automatically change the shell values when the component mounts,
 * and undo those changes when it unmounts.
 * Components that are "deeper" in the React hierarchy take precedence over higher ones.
 *
 * Example:
 *   <ShellControls
 *     values={{
 *       title: 'My Page',
 *       headerContent: <MyHeaderContent />,
 *       bodyPadding: false,
 *     }}
 *   />
 */
export function ShellControls(props: { values: Partial<ShellValues> }) {
  useShellControls(props.values);
  return null;
}

/**
 * Higher order component that adds a 'shellValues' prop to a component with the current resolved shell values.
 * (Legacy method for class components)
 *
 * @see ShellValues
 */
export const withShellControls = (Component) => (props) => {
  const shellValues = useResolvedShellValues();
  const shellContext = useContext(shellValuesContext);
  return <Component {...props} shellValues={shellValues} shellContext={shellContext} />;
};
