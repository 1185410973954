import * as Sentry from '@sentry/browser';
import { ServerEventsClient } from '@servicestack/client';
import UserProvider from 'Components/UserProvider';
import eventEmitter from 'Services/events';

const channels = ['home'];

const networkError = false;

/** @type {ServerEventsClient} */
let seClient = null;

const init = () => {
  seClient = new ServerEventsClient(import.meta.env.VITE_SERVICE_URL || '/', channels, {
    handlers: {
      GeneralMessage: (msg) => {
        eventEmitter.emit('general-message-received', msg);
      },

      NotificationReceivedMessage: (msg) => {
        eventEmitter.emit('notification-received', msg);
      },

      CurrencyProcessingCompleteMessage: (msg) => {
        eventEmitter.emit('currency-processing-complete', msg);
      },

      DispatcherQueriedMessage: (msg) => {
        eventEmitter.emit('dispatcher-started-investigation', msg);
      },

      UserUpdatedMessage: async () => {
        eventEmitter.emit('user-updated');
      },
      InvestigationUpdate: (msg) => {
        eventEmitter.emit('investigation-update', msg);
      },

      UserSuspendedMessage: () => {
        UserProvider.instance.logOff(
          'Your account has been suspended, please contact your administrator',
        );
      },

      NewSessionMessage: () => {
        UserProvider.instance.logOff('Session expired', true);
      },
      ConversationResponse: (conversation) => {
        eventEmitter.emit('conversation-response', conversation);
      },

      MessageResponse: (msg) => {
        eventEmitter.emit('message-response', msg);
      },
      StateHostStatusChanged: (msg) => {
        eventEmitter.emit('state-host-available', msg);
      },
      onConnect: (args) => {
        const secretKey = args?.secretKey;
        if (args?.userId) {
          UserProvider.instance.logOffUserOnInvalidSecretKey(secretKey);
        }
      },
    },
    onException: (err) => {
      Sentry.addBreadcrumb({
        message: 'SSE client exception',
        level: Sentry.Severity.Warning,
        data: err,
      });
    },
  }).start();
};

// Try to unregister before closing the browser
if (navigator.sendBeacon) {
  window.addEventListener('unload', () => {
    if (seClient?.connectionInfo) {
      navigator.sendBeacon(seClient.connectionInfo.unRegisterUrl);
    }
  });
}

export { init as default, seClient };
