class LocalStorage {
  set = (key, value) => {
    localStorage.setItem(key, value);
  };

  setObj = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  get = (key) => localStorage.getItem(key);

  getObj = (key) => {
    const data = localStorage.getItem(key);
    return JSON.parse(data);
  };

  remove = (key) => {
    localStorage.removeItem(key);
  };

  removeAll = () => localStorage.clear();
}

export default new LocalStorage();
